<template>
  <dashboard-filters-v2 class="StreamFilters">
    <template #primary>
      <slot name="primary" />
    </template>
    <template #secondary>
      <date-range-picker-base
        v-model="dateValueLocal"
        :shortcuts="dateRangeShortcuts"
        :picker-options="pickerOptions"
        value-format="timestamp"
        data-testid="StreamsFilters__DateRangePicker"
      />
    </template>
  </dashboard-filters-v2>
</template>

<script>
import { differenceInDays, isFuture } from 'date-fns'
import DateRangePickerBase from '@hypefactors/shared/js/components/filters/DateRangePickerBase'

/**
 * @module StreamFilters
 */
export default {
  name: 'StreamFilters',
  components: {
    DateRangePickerBase
  },
  props: {
    start: {
      type: Number,
      default: 0
    },
    end: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      dateRangeShortcuts: [
        { slug: 'two_weeks', label: '14_days' },
        { slug: 'month', label: 'mtd' }
      ],
      pickerOptions: {
        disabledDate: time =>
          // If we ar e2e testing we dont want to have any limits. See #988
          window.Cypress
            ? false
            : (isFuture(time) || Math.abs(differenceInDays(time, Date.now())) > 90)
      }
    }
  },
  computed: {
    dateValueLocal: {
      get () {
        return [this.start, this.end]
      },
      set ([start, end]) {
        this.$emit('update:start', start)
        this.$emit('update:end', end)
      }
    }
  }
}
</script>
