<template>
  <el-dialog
    :visible.sync="visibleLocal"
    :title="$t('components.report_mention_dialog.dialog_title')"
    class="ReportMentionDialog is-fullscreen-mobile"
    width="750px"
    data-testid="ReportMentionDialog"
  >
    <div class="m-b-m">
      {{ $t('components.report_mention_dialog.title') }}
    </div>
    <el-checkbox-group v-model="reportReasons" class="is-column">
      <el-checkbox
        v-for="reason in reasons"
        :key="reason"
        :label="reason"
        data-testid="reportReason"
      >
        {{ $t('components.report_mention_dialog.reasons.'+ reason) }}
      </el-checkbox>
    </el-checkbox-group>
    <template #footer>
      <v-button
        class="is-black"
        :disabled="!reportReasons.length"
        :loading="isReporting"
        data-testid="submitReportReason"
        @click="submitReport"
      >
        {{ $t('forms.send') }}
      </v-button>
    </template>
  </el-dialog>
</template>

<script>
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'

/**
 * @module ReportMentionDialog
 */
export default {
  name: 'ReportMentionDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    mention: {
      type: Object,
      default: null
    },
    streamId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      reasons: [
        'not_relevant',
        'text_looks_wrong',
        'image_looks_wrong',
        'outlet_information_looks_wrong',
        'article_information_looks_wrong',
        'do_not_want_to_see_mentions_from_this_outlet',
        'is_advertisement'
      ],
      reportReasons: [],
      isReporting: false
    }
  },
  computed: {
    visibleLocal: generateComputedSetterWithEmit('visible', 'update:visible')
  },
  methods: {
    async submitReport () {
      this.isReporting = true
      try {
        await this.$api.postData(`/streams/${this.streamId}/mention/report`, {
          mention_id: this.mention.id,
          reasons: this.reportReasons
        })
        this.$notify.success(this.$t('components.report_mention_dialog.sent_success'))
        this.reportReasons = []
        this.visibleLocal = false
        this.$emit('update:mention', null)
      } catch (e) {
        this.$displayRequestError(e)
      } finally {
        this.isReporting = false
      }
    }
  }
}
</script>
