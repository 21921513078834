<template>
  <div class="SendMentionsToEmail">
    <el-tooltip
      slot="reference"
      :content="$t('components.streams.send_mentions_to_email.send_mentions_to_email')"
      placement="top"
    >
      <v-button
        :disabled="selectedMentions.length === 0"
        class="is-black is-plain is-narrow"
        data-testid="sendToEmailsTrigger"
        @click="isVisible = true"
      >
        <span class="icon">
          <v-icon type="paper-plane" />
        </span>
      </v-button>
    </el-tooltip>

    <el-dialog
      :visible.sync="isVisible"
      :title="$t('components.streams.send_mentions_to_email.send_mentions_to_email')"
      class="SendMentionsToEmail is-fullscreen-mobile"
      width="750px"
      data-testid="SendMentionsToEmail"
    >
      <div class="m-b-m">
        Add Email addresses to share the selected mentions
      </div>

      <div class="m-b-m">
        <form-field :validator="$v.form.note" label="Note">
          <input
            v-model="form.note"
            type="text"
            class="input"
          >
        </form-field>

        <form-field
          label="Email Addresses"
          class="field__emailAddresses"
          :validator="$v.form.emails"
        >
          <tags-list
            v-model="form.emails"
            :validator="validEmailAddresses"
            name="emails"
          />
        </form-field>
      </div>

      <template #footer>
        <v-button
          class="is-black"
          :loading="isProcessing"
          data-testid="sendMentionsToEmailSubmit"
          @click="submit"
        >
          {{ $t('forms.send') }}
        </v-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { email, required } from 'vuelidate/lib/validators'

import Form from '@/services/forms/Form'
import TagsList from '@/components/core/TagsList'

const form = () => new Form({
  note: { value: '', rules: { required } },
  emails: { value: [], rules: { required } }
})

/**
 * @module SendMentionsToEmail
 */
export default {
  name: 'SendMentionsToEmail',

  components: {
    TagsList
  },

  props: {
    streamId: {
      type: String,
      required: true
    },
    selectedMentions: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      isVisible: false,
      isProcessing: false,
      form: form(),
      validEmailAddresses: {
        $each: {
          email: email
        }
      }
    }
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  watch: {
    streamId () {
      this.getLatestUsedEmailAddresses()
    }
  },

  mounted () {
    this.form.merge({
      'note': `${this.currentUser.first_name} shared the following mentions with you!`
    })

    this.getLatestUsedEmailAddresses()
  },

  validations () {
    return {
      form: this.form.rules()
    }
  },

  methods: {
    async getLatestUsedEmailAddresses () {
      await this.$api.get(`/streams/${this.streamId}/mentions/share/latest-emails`)
        .then(response => {
          this.form.merge({
            'emails': response.data.data.emails
          })
        })
        .catch(() => {})
    },

    async submit () {
      this.$v.form.$touch()

      if (this.$v.form.$error) return

      this.isProcessing = true

      const payload = {
        note: this.form.note,
        emails: this.form.emails,
        mentions: this.selectedMentions
      }

      await this.$api.post(`/streams/${this.streamId}/mentions/share`, payload)
        .then(response => {
          this.isVisible = false

          this.$v.form.$reset()

          this.$notify.success(this.$t('components.streams.send_mentions_to_email.sent_successfully'))

          this.$emit('finish')
        })
        .catch((error) => {
          this.$displayRequestError(error)
        })
        .finally(() => {
          this.isProcessing = false
        })
    }
  }
}
</script>
