<template>
  <el-dialog
    :title="$t('components.streams.confirm_mentions_modal.title')"
    :visible.sync="isVisibleLocal"
    class="ConfirmMentionsModal is-fullscreen-mobile"
    width="750px"
    data-testid="ConfirmSelectionDialog"
    @close="action.reject"
  >
    <div>
      <heading size="4" :serif="false">
        {{ $t('components.streams.confirm_mentions_modal.content') }}
      </heading>
      <table class="table is-full is-transparent has-cells-left is-striped">
        <tbody>
          <tr
            v-for="mention in selection"
            :key="mention.id"
            class="is-flex"
          >
            <td class="">
              <div class="icon">
                <clipping-type-icon :type="mention.type" />
              </div>
            </td>
            <td class="is-width-full">
              <span class="has-text-weight-semibold">{{ mention.media_title_name }}</span>:
              {{ mention.title || truncate(mention.content_body, 50) }}
            </td>
            <td>
              <v-anchor class="has-nowrap" :href="mention.source_link" target="_blank">
                {{ $t('components.streams.confirm_mentions_modal.link') }}
              </v-anchor>
            </td>
            <td class="">
              <el-checkbox-group v-model="selectedLocal" :disabled="!action.validator(mention)">
                <el-checkbox :label="mention.id" class="no-label" data-testid="confirmMentionCheckbox" />
              </el-checkbox-group>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="buttons is-aligned-justify">
        <v-button
          class="is-black is-plain"
          @click="isVisibleLocal = false"
        >
          {{ $t('forms.cancel') }}
        </v-button>
        <v-button
          class="is-black"
          :disabled="!selectedLocal.length"
          data-testid="continueProcessTrigger"
          @click="next"
        >
          {{ $t('general.next') }}
        </v-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import TruncateMixin from '@hypefactors/shared/js/mixins/TruncateMixin'

import VAnchor from '@hypefactors/shared/js/components/core/VAnchor'
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'

/**
 * @module ConfirmMentionsModal
 */
export default {
  name: 'ConfirmMentionsModal',
  components: { VAnchor },
  mixins: [TruncateMixin],
  props: {
    /**
     * Array of selected mention objects
     * @type {HF_Mention[]}
     */
    selection: {
      type: Array,
      required: true
    },
    /**
     * Whether the modal is visible
     */
    visible: {
      type: Boolean,
      default: false
    },
    /**
     * Used to control the Action's promise.
     * When resolve is called, it will call the action's resolver.
     * @type {MentionActionObject}
     */
    action: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      // holds all the selected mentions
      selectedLocal: []
    }
  },

  computed: {
    isVisibleLocal: generateComputedSetterWithEmit('visible', 'update:visible')
  },

  watch: {
    visible (isVis) {
      if (!isVis) return
      // if the value changes, we save all the ids locally
      this.selectedLocal = this.selection
        .filter((mention) => this.action.validator(mention))
        .map(mention => mention.id)
    }
  },
  methods: {
    /**
     * Calls the action's resolver
     * Passes the mentions and the passed payload
     */
    next () {
      const mentions = this.selection.filter(m => this.selectedLocal.includes(m.id))
      this.action.resolve({ mentions, payload: this.action.payload })
    }
  }
}
</script>
