<template>
  <div class="SendMentionsToBucket">
    <el-popover
      v-if="buckets.length"
      popper-class="rssFeedsDropdown"
    >
      <v-button
        v-for="bucket in buckets"
        :key="bucket.id"
        class="is-plain is-black is-flex is-narrow"
        data-testid="chooseRssFeedTrigger"
        @click="startConfirmationProcess({ id: bucket.id, name: bucket.name })"
      >
        {{ bucket.name }}
      </v-button>
      <v-separator />
      <router-link
        :to="{ name: 'settings.brand.rss' }"
        data-testid="mangeRssFeedsTrigger"
      >
        {{ $t('components.streams.send_mentions_to_bucket.manage_feeds') }}
      </router-link>
      <el-tooltip
        slot="reference"
        :content="$t('components.streams.send_mentions_to_bucket.send_mentions_to_bucket')"
        placement="top"
      >
        <v-button
          :loading="isProcessing"
          :disabled="!selectedInitially"
          class="is-black is-plain is-narrow"
          data-testid="sendToFeedsTrigger"
        >
          <span class="icon">
            <v-icon type="feed" />
          </span>
        </v-button>
      </el-tooltip>
    </el-popover>
    <v-button
      v-else
      class="is-black is-plain is-narrow"
      data-testid="noFeedBucketsTrigger"
      :disabled="!selectedInitially"
      @click="showNoFeedBucketsError"
    >
      <span class="icon">
        <v-icon type="feed" />
      </span>
    </v-button>
  </div>
</template>

<script>
import { RSSBucketApiService } from '@/services/api/RSSBucketApiService'

/**
 * @module SendMentionsToBucket
 */
export default {
  name: 'SendMentionsToBucket',
  props: {
    selectedInitially: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      buckets: [],
      isProcessing: false
    }
  },
  mounted () {
    this.fetchBuckets()
  },
  methods: {
    /**
     * Fetch all the RSS buckets
     * @return {Promise<void>}
     */
    async fetchBuckets () {
      this.buckets = await RSSBucketApiService.fetchAll(this.activeBrandId)
    },
    /**
     * Starts the send mention process
     * @param {Object} bucket - the RSS bucket
     * @see {MentionActionObject}
     * @return {Promise}
     */
    startConfirmationProcess (bucket) {
      return new Promise((resolve, reject) => {
        this.isProcessing = true
        this.$emit('select', {
          resolve,
          reject,
          payload: { bucketId: bucket.id, bucketName: bucket.name },
          title: this.$t('components.streams.send_mentions_to_bucket.confirm_mentions_title', { name: bucket.name }),
          validator: ({ type }) => {
            return type === 'web'
          }
        })
      })
        .then(async ({ mentions, payload }) => {
          this.$emit('processing')
          await this.sendToBucket(mentions, payload.bucketId)
          this.$notify.success(this.$t('components.streams.send_mentions_to_bucket.added_to_bucket_success', {
            count: mentions.length,
            name: payload.bucketName
          }))
          this.$emit('finish')
        })
        .catch((error) => {
          this.$displayRequestError(error)
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    /**
     * Adds the mentions to the provided RSS bucket
     * Gets called after confirmation finishes and the resolve
     * is called in the {@see MentionActionObject}
     * @param {string[]} mentions
     * @param {string} bucketId
     * @return {Promise}
     */
    sendToBucket (mentions, bucketId) {
      const payload = mentions.map(m => ({ id: m.id, published_at: m.published_at }))
      return RSSBucketApiService.addItemsToBucket(this.activeBrandId, bucketId, { mentions: payload })
    },
    /**
     * Shows a modal to create a feed, if no feeds exist.
     * Starts the confirmation process.
     * @return {Promise<void>}
     */
    async showNoFeedBucketsError () {
      try {
        const { value } = await this.$prompt(
          this.$t('components.streams.send_mentions_to_bucket.create_feed_title'),
          this.$t('components.streams.send_mentions_to_bucket.no_feeds_error')
        )
        const bucket = await RSSBucketApiService.create(this.activeBrandId, {
          name: value,
          sorting_type: 'manual'
        })
        this.buckets.push(bucket)
        this.startConfirmationProcess(bucket)
      } catch (err) {
        this.$displayRequestError(err)
      }
    }
  }
}
</script>
